import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../../components/chat-channel/Container";
import Layout from "../../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../../components/common/Icons";
import { CardsThree } from "../../homepage";
import FooterForm from "../../../components/common/FooterForm";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import TopFormWithImage from "../../../components/TopFormCenterImage";
import {
  autoDesigner,
  chatChannel,
  logs,
  dialog,
  onprem_icon,
  analytics,
} from "../../conversational-ai-platform/chatbot-builder";
import { RequestForm } from "../../../components/form";
const TopImage = require("../../../assets/img/feature/training_feature/train_header.png");

export const icon1 = require("../../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../../assets/img/feature/sec_2_bg.png");

export const cardOne = require("../../../assets/images/homepage/group1.png");
export const cardTwo = require("../../../assets/images/homepage/group4.png");
const section1 = require("../../../assets/img/feature/training_feature/logs_sec_2.png");
const section2 = require("../../../assets/img/feature/training_feature/features_main_sec_7.png");

export const otherFeatures = [
  {
    image: dialog,
    alt: "Dialog Designer",
    header: "Dialog Designer",
    content:
      "Create engaging and guided conversations for your chatbot to respond to end-users.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: autoDesigner,
    alt: "autoDesigner",
    header: "Automation Designer",
    content:
      "Automate IT and HR processes through seamless integration with your existing applications.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: onprem_icon,
    alt: "onprem_icon",
    header: "On-Prem Connect",
    content:
      "Establish a secure connectivity between your on-premise apps and Workativ to automate your workflows.",
    link: "/conversational-ai-platform/on-prem-app-connector",
  },
  {
    image: chatChannel,
    alt: "Chatbot Channel Integration",
    header: "Chatbot Channel Integration",
    content:
      "Deploy the chatbot in a single click on your enterprise chat channel like Teams or Slack.",
    link: "/conversational-ai-platform/chatbot-channels",
  },
  {
    image: analytics,
    alt: "Analytics",
    header: "Analytics",
    content:
      "Gather insight to craft better experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/featuresanalytics",
  },

  {
    image: logs,
    alt: "Logs",
    header: "Logs",
    content:
      "Track conversations and automation executions to improve chatbot performance.",
    link: "/conversational-ai-platform/featureslogs",
  },
];

const featureContent = [
  {
    icon: icon1,
    header: "Conversations",
    content:
      "A conversational assistant means that the chatbots’ responses are more human than robotic, canned responses. To enable a better user experience for employees, you can review entire conversations between a user and the chatbot.",
    image: section1,
  },
  {
    icon: icon2,
    header: "User texts",
    content:
      "The logs and analytics are incredibly beneficial in training the chatbot. The ‘texts’ or ‘intents’ of users can be retrieved through ‘logs’ to understand the language and words used in conversations for support. Studying intents and adding them to dialogs enables the chatbot to deliver contextually relevant support.",
    image: section2,
  },
];
export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

export default function TrainingPage() {
  return (
    <>
      <TitleAndMetaTags
        title="Conversational AI Chatbot Training | Workativ Assistant Features"
        description="Train and continue to develop the Chatbot into a full-fledged support channel for employee support."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Chabot Training | Workativ Assistant Features"
        ogDescription="Train and continue to develop the Chatbot into a full-fledged support channel for employee support."
      />
      <Container>
        <Layout logoFor="ASSISTANT">
          <TopFormWithImage image={TopImage} altImage={"train assistant"}>
            <TopFormWithImage.Header>Train the Chatbot</TopFormWithImage.Header>
            <TopFormWithImage.Content>
              The training module is your space to train the chatbot and
              continually develop the chatbot into a full-fledged autonomous
              support channel.
            </TopFormWithImage.Content>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>
          {featureContent.map((feature, index) => {
            const isFeature = index === 0;
            if (index % 2 == 0) {
              return (
                <RightImageWithContentFeature
                  isFeature={isFeature}
                  image={feature.image}
                  altImage={feature.header}
                >
                  <RightImageWithContentFeature.Header>
                    <h3>
                      <img
                        className="icon-header-left"
                        src={feature.icon}
                        alt="header-icon"
                      />
                      <span>{feature.header}</span>
                    </h3>
                  </RightImageWithContentFeature.Header>

                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                  </RightImageWithContentFeature.SubHeader>
                </RightImageWithContentFeature>
              );
            }
            return (
              <div className="features_container_left">
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.header}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <h3>
                      <img
                        className="icon-header-left"
                        src={feature.icon}
                        alt="header-icon"
                      />
                      <span>{feature.header}</span>
                    </h3>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.SubHeader>
                    <p>{feature.content}</p>
                  </LeftImageWithContent.SubHeader>
                </LeftImageWithContent>
              </div>
            );
          })}
          <section className="cards_features">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2>Tailored to employee needs</h2>
                      <p>
                        Workativ’s one of a kind conversational AI platform,
                        purpose-built for workplace employee support automation,
                        incorporates a very easy-to-use design that requires no
                        coding at all.
                      </p>
                    </div>
                  </div>
                  {otherFeatures.map((feature) => (
                    <div className="col-md-6 col-lg-4 col-12 col-sm-12 col-12 cards_features_header_div">
                      <img src={feature.image} alt={feature.alt} />
                      <h5>{feature.header}</h5>
                      <p>{feature.content}</p>
                      <div className="card_link_landing">
                        <a href={feature.link} className="url_manipulation">
                          Know more{" "}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <div className="isfooter_form">
          <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}
